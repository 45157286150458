import { graphql } from 'gatsby';
import React from 'react';
import useToggle from '@hooks/useToggle';
import Layout from '@layouts/Layout';
import SEO from '@components/molecules/SEO';
import TopFixedSection from '@components/organisms/TopFixedSection';

const PolicyPages = (props) => {
  const { toggled, handleToggleClick } = useToggle(false);

  const {
    data: {
      allWp: {
        nodes
      },
      wpPage: { seo, title, uri, content },
      wp: {
        siteSettings: {
          siteSettings: { menuText, menuLogo },
        },
      },
    },
  } = props;

  return (
    <Layout currentLanguage="bg" hideParallax>
      <SEO title={title} uri={uri} headJson={seo?.headJSON} />
      <TopFixedSection
        menuText={menuText}
        menuLogo={menuLogo}
        handleToggleClick={handleToggleClick}
        toggled={toggled}
        generalSettingsUrl={nodes[0].allSettings.generalSettingsUrl}
      />
      <div
        className="flex flex-col gap-12 min-h-screen py-24 md:py-[160px] px-[16px] md:px-[15%] bg-carbon"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Layout>
  );
};

export default PolicyPages;

export const query = graphql`
  query POLICY_PAGES($id: String!) {
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    wpPage(id: { eq: $id }) {
      title
      uri
      content
      seo {
        headJSON
      }
    }
    wp {
      siteSettings {
        siteSettings {
          menuText
          menuLogo {
            altText
            mediaItemUrl
          }
        }
      }
    }
  }
`;
